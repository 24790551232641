import { RuntimeConfig } from "nuxt/schema";
import { ArticlePreviewType } from "~/types/article/ArticlePreviewType";

export const ArticlePreviewEntity = function (data: any, config: RuntimeConfig): ArticlePreviewType {
    return {
        slug: data?.attributes?.slug,
        title: data?.attributes?.title,
        date: data?.attributes?.publishedAt,
        category: data?.attributes?.interesting_facts_category?.data?.attributes?.title,
        picture: config.public.ssrMediaBaseUrl + data?.attributes?.picture?.data?.attributes?.url,
        url: `/interesting-facts/fact-${data?.attributes?.slug}`,
    };
};